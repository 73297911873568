export const sidemenuVariant = {
  initial: { scale: 0.96, y: 30, opacity: 0 },
  enter: { scale: 1, y: 0, opacity: 1, transition: { duration: 0.5, ease: [0.48, 0.15, 0.25, 0.96] } },
  exit: {
    scale: 0.6,
    y: 100,
    opacity: 0,
    transition: { duration: 0.2, ease: [0.48, 0.15, 0.25, 0.96] }
  }
};

export const tabContentVariant = {
  active: {
    display: "block",
    transition: {
      staggerChildren: 0.2
    }
  },
  inactive: {
    display: "none"
  }
};

export const tabItemVariant = {
  active: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4
    }
  },
  inactive: {
    opacity: 0,
    y: 10,
    transition: {
      duration: 0.4
    }
  }
};

export const mobileMainVariant = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
}

export const rightVariant = {
  hidden: { opacity: 0, x: 100, y: 0, transition: { duration: 0.4 } },
  enter: { opacity: 1, x: 0, y: 0, transition: { duration: 0.4 } },
  exit: { opacity: 0, x: 100, y: 0, transition: { duration: 0.4 } },
}

export const leftVariant = {
  hidden: { opacity: 0, x: -100, y: 0, transition: { duration: 0.4 } },
  enter: { opacity: 1, x: 0, y: 0, transition: { duration: 0.4 } },
  exit: { opacity: 0, x: -100, y: 0, transition: { duration: 0.4 } },
}

export const topVariant = {
  hidden: { opacity: 0, x: 0, y: 100, transition: { duration: 0.4 } },
  enter: { opacity: 1, x: 0, y: 0, transition: { duration: 0.4 } },
  exit: { opacity: 0, x: 0, y: 100, transition: { duration: 0.4 } },
}
