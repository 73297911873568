
import { Link } from "@/helpers/common_helper";
import { AiOutlineHome, AiOutlineDollarCircle, AiOutlinePercentage, AiOutlinePhone, AiOutlineGlobal, AiOutlineCrown, AiOutlineLogout,Coin} from "react-icons/ai";
import { BsCoin} from "react-icons/bs";
import { motion } from 'framer-motion';
import { sidemenuVariant } from "@/config/variants";
import { removeCookies } from 'cookies-next';

function Navbar(props) {
    const { t } = props;

    const _logout = () => {
        removeCookies("user");
        window.location = location.pathname;
    }

    const menu =  [
        {
            isLogin :false,
            label : "Home",
            url : "/",
            icon : <AiOutlineHome/>
        },
        {
            isLogin :true,
            label : "Funds",
            url : "/account/funds",
            icon : <AiOutlineDollarCircle/>
        },
        {
            isLogin :false,
            label : "Promos",
            url : "/promotion",
            icon : <AiOutlinePercentage/>
        },
        {
            isLogin :false,
            label : "Contact Us",
            url : "/contactus",
            icon : <AiOutlinePhone/>
        },
        {
            isLogin :false,
            label : "Language",
            url : "/language",
            icon : <AiOutlineGlobal/>
        },
        {
            isLogin :false,
            label : "VIP",
            url : "/vip",
            icon : <AiOutlineCrown/>
        },
        {
            isLogin :false,
            label : "REWARD",
            url : "/reward",
            icon : <BsCoin/>
        }
    ];

    return (
        <motion.div
            initial="initial"
            animate="enter"
            exit="exit"
            variants={{ enter: { transition: { staggerChildren: 0.1 } } }}
        >
        {/* <!-- Sidemenu start here --> */}
        <div
            className="offcanvas offcanvas-start show"
            style={{ visibility: "visible" }}  >
            <div className="offcanvas-body">
                <div className="mobile-menu">
                    <ul>
                        {
                            menu.map((value,key) => {

                                return (
                                    <motion.li key={key} variants={sidemenuVariant} style={value.isLogin && props.session.user == null ? {display: "none"} : {} }>
                                        <Link href={value.url}>
                                            <a >
                                                {value.icon}
                                                {t(value.label)}
                                            </a>
                                        </Link>
                                    </motion.li>
                                )

                            })
                        }
                        {props.session.user != null ?
                            <motion.div variants={sidemenuVariant}>
                                <li>
                                    <a onClick={_logout}>
                                        <AiOutlineLogout />
                                        {t('Logout')}
                                    </a>
                                </li>
                            </motion.div> : <></>
                        }
                    </ul>
                </div>
            </div>
        </div>
        {/* <!-- Sidemenu ends here --> */}
        </motion.div> 
    )
}

export default Navbar;
