
/* Start yup package */
// reference yup library https://github.com/jquense/yup
import { yupResolver } from '@mantine/form';
import * as yup from "yup";
/* End yup package */
import { i18n } from 'next-i18next'

export default (validation) => {
    const t = i18n.t.bind(i18n);
    try {
        var yups = {};
        validation.forEach(element => {
            var field = element.field
            yups[field] = yup;

            element.rules.forEach(rule => {
                var rule = rule.split("|")
                switch (rule[0]) {
                    case "number":
                        yups[field] = yups[field].number().typeError(`${t(element.label)}` + t("cant_be_blank"))
                        break;
                    case "min":
                    case "max":
                    case "lessThan":
                    case "moreThan":
                        yups[field] = yups[field][rule[0]](rule[1] ?? 0, rule[2] ? t(rule[2]) : undefined)
                        break;
                    case "round":
                        yups[field] = yups[field][rule[0]](rule[1] ?? "round")
                        break;
                    case "match":
                        yups[field] = yups[field].oneOf([yup.ref(rule[1])], `* ${t((rule[2] ?? rule[1]))}` + t("not_match"))
                        break;
                    case "no_symbol": //only a-z and 0-9 (use by code string|no_symbol|required)
                        yups[field] = yups[field].matches(/^[A-za-z0-9]+$/, t("no_special_character_is_allowed"))
                        break
                    case "only_string": //only a-z and white space (use by code string|only_string|required)
                        yups[field] = yups[field].matches(/^[A-za-z ]+$/, t("no_numeric_and_special_character_is_allowed"))
                        break
                    // string,required,email
                    default:
                        yups[field] = yups[field][rule[0]]()
                        break;
                }
            });
            yups[field] = yups[field].label(t(element.label))
        });

        yup.setLocale({
            mixed: {
                notType: t("invalid_input"),
                required: t("label_validation"),
                email: t("email_validation")
            },
            string: {
                min: t("min_validation"),
                max: t("max_validation"),
                email: t("email_validation")
            },
            number: {
                min: t("min_validation"),
                max: t("max_validation"),
                integer: t("contain_integer")
            },
        });

        return yupResolver(yup.object(yups).required());
    }
    catch (error) {

    }
}