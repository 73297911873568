export const login = [
    {
        'field': 'username',
        'label': "Email",
        'rules': ['string', 'required'],
    },
    {
        'field': 'password',
        'label': 'Password',
        'rules': ['string', 'required'],
    },
];

export const signup = [
    {
        'field': 'username',
        'label': "Username",
        'rules': ['string', 'required', 'min|5', 'max|20', 'no_symbol']
    },
    {
        'field': 'phone_no',
        'label': "Phone No",
        'rules': ['string', 'required']
    },
    {
        'field': 'password',
        'label': 'Password ',
        'rules': ['string', 'required', 'min|5|password_validation', 'max|20|password_validation']
    },
    {
        'field': 'email',
        'label': 'Email',
        'rules': ['string', 'email', 'required']
    },
    {
        'field': 'otp',
        'label': 'OTP Verification Code',
        'rules': ['string', 'required']

    },
];

export const resetpassword = [
    {
        'field': 'email',
        'label': "Email Address",
        'rules': ['string', 'email', 'required'],
    },
    // {
    //     'field': 'password',
    //     'label': 'New Password ',
    //     'rules': ['string', 'required', 'min|5|password_validation', 'max|15|password_validation'],
    // },
    // ,
    // {
    //     'field': 'confirm_password',
    //     'label': 'Confirm Password',
    //     'rules': ['string', 'required', 'match|password|Password'],
    // }
];

export const profile = [
    {
        'field': 'full_name',
        'label': 'Full Name',
        'rules': ['string', 'required', 'min|5', 'max|20']
    },
    {
        'field': 'username',
        'label': 'Username',
        'rules': ['string', 'required', 'min|5', 'max|20', 'no_symbol']
    },
    {
        'field': 'email',
        'label': 'Email',
        'rules': ['string', 'required', 'email']
    },
    {
        'field': 'phone_no',
        'label': 'Phone',
        'rules': ['string', 'required']
    }
]

export const changepassword = [
    {
        'field': 'current_password',
        'label': 'Current Password ',
        'rules': ['string', 'required'],
    },
    {
        'field': 'new_password',
        'label': 'New Password ',
        'rules': ['string', 'required', 'min|5|password_validation', 'max|15|password_validation'],
    },
    {
        'field': 'confirm_password',
        'label': 'Confirm Password',
        'rules': ['string', 'required', 'match|password|password'],
    }
];

export const addbankaccount = [
    {
        'field': 'bank_id',
        'label': 'Bank',
        'rules': ['string', 'required'],
    },
    {
        'field': 'acc_no',
        'label': 'Bank Account ',
        'rules': ['string', 'required'],
    }
];

export const saveshippingaddress = [
    {
        'field': 'full_name',
        'label': "Full Name",
        'rules': ['string', 'required'],
    },
    {
        'field': 'phone_no',
        'label': 'Mobile Number',
        'rules': ['string', 'required'],
    },
    {
        'field': 'address',
        'label': 'Address',
        'rules': ['string', 'required'],
    },
    {
        'field': 'state',
        'label': 'State',
        'rules': ['string', 'required'],
    },
    {
        'field': 'city',
        'label': 'City',
        'rules': ['string', 'required'],
    },
    {
        'field': 'postcode',
        'label': 'Post code',
        'rules': ['string', 'required'],
    },
];
