
import { Link } from "@/helpers/common_helper";

import Redux from "@/action"

import React from 'react';
import { login as validation } from "@/config/form_validation";
import form_helper from "@/helpers/form_helper";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import { isMobile } from 'react-device-detect';
import { useRouter } from 'next/router';
import { FaUserCircle } from "react-icons/fa";

var resolver;

function Index({ setOpened }) {
    resolver = resolver ?? form_helper(validation);
    const { store } = Redux();
    let mobileView = <></>

    if (isMobile) {
        const router = useRouter()

        // mobileView = <div className="top-download-section">
        //     <div className="right-part">
        //         <div className="app-icon">
        //             <img src="/assets/images/app-icon.png" />
        //         </div>
        //         <div className="app-name">
        //             <h3>Uootball</h3>
        //             <span>在Ufootball App打开</span>
        //         </div>
        //     </div>
        //     <div>
        //         <a className="download-app-btn" href={"ufootball:/" + router.asPath} target="_blank">打开</a>
        //     </div>
        // </div>
    }

    return <>
        {/* < !--header--> */}
        <header className="sticky-top position-fixed w-100">
            {mobileView}
            <div className="header py-xxl-3 py-md-2 sticky-top">
                <div className="container d-flex">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                            <div className="mp-logo-banner">
                                <Link href="/">
                                <img src="/assets/images/Ulogofootballlong.jpg" alt="Ufootball Logo" className="mp-logo-img" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Link href="/profile">
                    <a>
                        <FaUserCircle className="header-account" size={25} />
                    </a>
                </Link> */}
            </div>
        </header>
        {/* <!-- header ends here --> */}
    </>;
}

export default Index