
import { Link } from "@/helpers/common_helper";
import { AiOutlineHome,AiFillDollarCircle,AiOutlineUser,AiOutlinePercentage,AiOutlineWechat } from "react-icons/ai";

export default function Footermenu({active, t}){
    const menu =  [
        {
            id : "home",
            label : "Home",
            url : "/",
            icon : <AiOutlineHome/>
        },
        {
            id : "funds",
            label : "Funds",
            url : "/account/funds",
            icon : <AiFillDollarCircle/>
        },
        {
            id : "dashboard",
            label : "My Account",
            url : "/account/dashboard",
            icon : <AiOutlineUser/>
        },
        {
            id : "promotion",
            label : "Promos",
            url : "/promotion",
            icon : <AiOutlinePercentage/>
        },
        {
            id : "livechat",
            label : "Live Chat",
            url : "#",
            icon : <AiOutlineWechat/>
        }
    ];

    return <>
        <div className="footer-menu">
            <ul>
                {
                    menu.map(
                        (value, key) => 
                        <li className={`${active == value.id ? "active" : ""}`} key={key}>
                            <Link href={value.url}><a>
                                {value.icon}
                                <h3>{t(value.label)}</h3>
                            </a></Link>
                        </li>
                    )
                }
            </ul>
        </div>
    </>
}
