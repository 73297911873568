
import { language, site_name } from "@/config/site";
import { Link } from "@/helpers/common_helper";
import { MdOutlineArrowDropDown, MdLanguage } from "react-icons/md"
import { BsInstagram, BsYoutube } from "react-icons/bs"
import { GrFacebook } from "react-icons/gr"
import { FaTiktok } from "react-icons/fa"

export default function Footer(props) {
    const { t } = props;

    return <>
        {/* < !--footer start here-- > */}
        <footer className="footer">
            <div className="container">
                <div className="top-footer">
                    <div className="row">
                        <div className="col-4 me-auto">
                            <Link href="/">
                                <a><img
                                    src="/assets/images/logo/logo.jpg"
                                    className="img-fluid"
                                    alt="site"
                                /></a>
                            </Link>
                        </div>
                        <div className="col-md-2 d-flex footer-language ps-3 pe-1">
                            <MdLanguage className="my-auto" size={22} />
                            <p className="text-center m-1">{language[t("lang")]}</p>
                            <MdOutlineArrowDropDown className="my-auto ms-auto" size={22} />
                        </div>
                    </div>
                </div>
                <div className="middle-footer">

                    <div
                        className="footer-navbar text-center row"
                    >
                        <div className="col social-icon">
                            <GrFacebook />
                            <BsInstagram />
                            <FaTiktok />
                            <FaTiktok />
                            <BsYoutube />
                        </div>
                        <div>
                            Copyright {`${site_name}`} @ 2021-2022. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/* <!--footer ends here-- > */}
    </>
}