import Footer from "@/components/mobile/layout/footer"
import Header from "@/components/mobile/layout/header"
import Footermenu from "@/components/mobile/layout/footermenu"
import { useRouter } from 'next/router';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import Sidemenu from "@/components/mobile/layout/sidemenu"
import { Drawer } from '@mantine/core';
import { useState, useLayoutEffect, useEffect } from "react";
import { motion } from 'framer-motion';
import { mobileMainVariant } from "@/config/variants";
import { isMobile } from 'react-device-detect';
import LandingPage from "./layout/landingpage";
import LanguageModal from "../languagemodal";
import { _getURL, _postURL } from "@/api/index";

export const HeaderNavbar = ({ children, page = "", ...props }) => {
    const [opened, setOpened] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    
    return <>
        {showHeader && <Header setOpened={setOpened} />}
        {/* <!-- main start here --> */}
        <div className={isMobile ? "body-content-container-include-mobile-view" : "body-content-container"}>
            <motion.main variants={mobileMainVariant}
                initial="hidden"
                animate="enter"
                exit="exit"
                transition={{ type: 'linear' }} >
                {children}
            </motion.main>
        </div>
        {/* <!-- main ends here --> */}
        <Drawer
            opened={opened}
            onClose={() => setOpened(false)}
            withCloseButton={false}
            zIndex={2000}
            size="194"
        >
            <Sidemenu {...props} />
        </Drawer>
        {/* {props.session.user != null ? <Footermenu active={page} {...props} /> : ''} */}
        {/* <!-- footer menu ends --> */}
    </>;
}


export const HeaderTitle = ({ children, title = "", page = "", HeaderRight = () => <></>, ...props }) => {
    const router = useRouter()
    let mobileView = <></>
    if (isMobile) {
        // mobileView = <div className="top-download-section">
        //     <div className="right-part">
        //         <div className="app-icon">
        //             <img src="/assets/images/app-icon.png" />
        //         </div>
        //         <div className="app-name">
        //             <h3>Uootball</h3>
        //             <span>在Ufootball App打开</span>
        //         </div>
        //     </div>
        //     <div>
        //         <a className="download-app-btn" href={"ufootball:/" + router.asPath} target="_blank">打开</a>
        //     </div>
        // </div>
    }

    return <>
        <style>{`
        body {
          background-color: white;
        }
      `}</style>

        <header className="container page-heading ">
            {mobileView}
            <div className="d-flex position-relative py-3">
                <div className="back-to-home" onClick={async () => {
                    if (typeof window !== 'undefined' && +window?.history?.state?.idx > 0) {
                        router.back()
                    } else {
                        // let history = createBrowserHistory();
                        // history.push("/")
                        router.back()
                    }
                }
                }>
                    <MdOutlineArrowBackIosNew size={0} />
                </div>
                <div className="w-100 text-center">
                    <h6 className="m-auto">{title}</h6>
                </div>
                <div className="page-heading-right">
                    <HeaderRight />
                </div>
                {props._search && props._search()}
            </div>
        </header>

        {/* <!-- main start here --> */}
        <div className={isMobile ? "body-content-container-include-mobile-view" : "body-content-container"} >
            {children}
        </div>
        {/* <!-- main ends here --> */}
    </>;
}

export const HeaderTitleOnly = ({ children, title = "" }) => {
    const router = useRouter()

    return <>
        <header className="container page-heading">
            <div className="back-to-home" onClick={async () => {
                if (typeof window !== 'undefined' && +window?.history?.state?.idx > 0) {
                    await router.back()
                } else {
                    // let history = createBrowserHistory();
                    // history.push("/")
                    await router.back()
                }
            }
            }>
                <FaAngleLeft />
            </div>
            <h1>{title}</h1>
        </header>
        <motion.main variants={mobileMainVariant}
            initial="hidden"
            animate="enter"
            exit="exit"
            transition={{ type: 'linear' }} >
            {children}
        </motion.main>
    </>;
}